import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Typography, Grid, Box } from '@mui/material';

import PageLayout from '../components/pageLayout';
import Markdown from '../components/markdown';
import Video from '../components/video';

import { genHelmet, readableDate } from '../actions/baseActions';

import theme from '../gatsby-theme-material-ui-top-layout/theme';

const styles = {
  root: {
    background: '#fff',
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(7.5),

    minHeight: '100vh',
    textAlign: 'left',
    // width: '100%',
    overflowX: 'hidden',
    // maxWidth: '960px',
    margin: 'auto',
  },
  title: {
    margin: `0 auto ${theme.spacing(4)} auto`,
  },
  markdown: {
    marginTop: theme.spacing(4),
  },
  gridRoot: {
    backgroundColor: '#fff',
  },
  coverImage: {
    width: '100%',
    height: 'auto',
  },
  coverImageWrapper: {
    margin: `-${theme.spacing(12)} -${theme.spacing(3)} 0 -${theme.spacing(3)}`,
  },
  videoWrapper: {
    margin: `${theme.spacing(3)} 0`,
  },
};

const PostTemplate = (props) => {
  const post = props.data.contentfulPost;
  const {
    coverImage,
    createdAt,
    headline,
    title,
    publishedDate,
    videoUrl,
    markdown,
  } = post;

  return (
    <>
      {genHelmet(post, {
        type: 'post',
        siteData: props.pageContext.site,
        path: props.path,
      })}

      <PageLayout location={props.location}>
        <Box sx={styles.coverImageWrapper}>
          <GatsbyImage
            image={getImage(coverImage.gatsbyImageData)}
            title={coverImage.title}
            alt={coverImage.description}
            style={styles.coverImage}
          />
        </Box>

        {/* --- MAIN BODY --- */}
        <Box sx={styles.root}>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            justifyContent="center"
            sx={styles.gridRoot}
          >
            {/* TITLE */}
            <Grid item xs={12} lg={9}>
              <Box sx={styles.title}>
                <Typography variant="h4"> {title} </Typography>
              </Box>
              <Typography variant="body2">
                {' '}
                {readableDate(publishedDate ? publishedDate : createdAt)}{' '}
              </Typography>
            </Grid>

            {/* VIDEO */}
            {videoUrl && (
              <Grid item xs={12} lg={9} sx={styles.videoWrapper}>
                <Video videoSrcURL={videoUrl} videoTitle={title} />
              </Grid>
            )}

            {/* MARKDOWN */}
            {markdown && (
              <Grid item xs={12} lg={9} sx={styles.markdown}>
                <Markdown markdown={markdown} />
              </Grid>
            )}

            {!markdown && headline && (
              <Grid item xs={12} lg={9}>
                <Typography>{headline}</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </PageLayout>
    </>
  );
};

export default PostTemplate;

export const PostQuery = graphql`
  query PostById($id: String!) {
    contentfulPost(contentful_id: { eq: $id }) {
      slug
      title
      # locationString
      # featured
      headline
      createdAt
      updatedAt
      publishedDate
      videoUrl
      textInImage
      markdown {
        childMarkdownRemark {
          html
        }
      }
      coverImage {
        title
        description
        gatsbyImageData(width: 1500, formats: JPG)
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
    }
  }
`;
